import {
  FormField,
  Select,
} from '@cloudscape-design/components';
import { useQuery } from '@tanstack/react-query';
import { getApp } from 'firebase/app';
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useFirestoreCollectionData } from 'reactfire';

function ProjectSelector({
  setProject,
  value = null,
  error = '',
  includeOther = false,
}) {
  const [internalProjects, setInternalProjects] = useState([]);

  useEffect(() => {
    (async () => {
      const functions = getFunctions(getApp());
      const getInternalPartNumbers = httpsCallable(functions, 'getInternalPartNumbers');

      const { data } = await getInternalPartNumbers();
      setInternalProjects(data);
    })();
  }, []);
  const internalProjectOptions = useMemo(() => internalProjects.map((project) => ({
    value: project.value,
    label: `${project.value} - ${project.title}`,
  })).sort((a, b) => {
    if (a.value < b.value) return 1;
    if (a.value > b.value) return -1;
    return 0;
  }), [internalProjects]);

  const { status: customerOrdersStatus, data: customerOrders = [] } = useFirestoreCollectionData(
    query(
      collection(getFirestore(), 'orders'),
      where('status', '==', 'In progress'),
    ),
    { idField: 'id' },
  );

  const { data: customerLineItemOptions = [], isPending } = useQuery({
    queryKey: ['lineItems', customerOrders],
    queryFn: async () => {
      if (!customerOrders?.length) return [];

      const lineItems = [];
      const projectIds = new Set();

      customerOrders.forEach((order) => {
        order.shipments.forEach((shipment) => {
          shipment.lineItems.forEach((lineItem) => {
            const lineItemId = lineItem.id;
            projectIds.add(lineItem.projectId);
            lineItems.push({
              value: lineItem.projectId,
              label: `${lineItem.projectId} - ${order.customer}`,
              orderId: order.id,
              lineItemId,
              steps: lineItem.steps,
            });
          });
        });
      });

      // Sort the line items
      lineItems.sort((a, b) => (a.value < b.value ? 1 : -1));

      // Query Firestore for project filenames
      const projectIdsArr = Array.from(projectIds);
      const projectsQuery = query(collection(getFirestore(), 'projects'), where('name', 'in', projectIdsArr));
      const querySnapshot = await getDocs(projectsQuery);

      // Create a map of project IDs to filenames
      const idFileMap = {};
      querySnapshot.forEach((doc) => {
        const project = doc.data();
        idFileMap[project.name] = project.filename;
      });

      // Add filenames to the line items
      return lineItems.map((lineItem) => ({
        ...lineItem,
        label: `${lineItem.label} - ${idFileMap[lineItem.value]}`,
      }));
    },
    enabled: !!customerOrders?.length, // Only run the query if there are customer orders
  });

  const options = useMemo(() => {
    const opts = [
      { label: 'Customer Projects', options: customerLineItemOptions },
      { label: 'Internal Projects', options: internalProjectOptions },
    ];
    if (includeOther) {
      opts.push({
        label: 'Other',
        options: [
          { value: 'other', label: 'Other...' },
        ],
      });
    }
    return opts;
  }, [customerLineItemOptions, internalProjectOptions, includeOther]);

  return (
    <FormField
      label="Project"
      description={(
        <span>
          {'Don\'t see the project you\'re looking for? Add customer projects '}
          <a target="_blank" href="https://rowy.admin.parallelfluidics.com/table/projects" rel="noreferrer">here</a>
          {' or internal projects '}
          <a target="_blank" href="https://www.notion.so/parallelfluidics/Part-Number-Databases-e357a39850184d9ea21b9b1539a3d95f" rel="noreferrer">here</a>
          .
        </span>
      )}
      stretch
      errorText={error}
    >
      <Select
        selectedOption={value}
        onChange={({ detail }) => setProject(detail.selectedOption)}
        filteringType="auto"
        options={options}
        loadingText="Loading projects"
        placeholder="Choose a project"
        statusType={
          internalProjectOptions.length === 0 || customerOrdersStatus === 'loading' || isPending
            ? 'loading' : 'finished'
        }
      />
    </FormField>

  );
}

ProjectSelector.propTypes = {
  setProject: PropTypes.func.isRequired,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  error: PropTypes.string,
  includeOther: PropTypes.bool,
};

export default ProjectSelector;
