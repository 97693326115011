import { InspectionValues, StepTypes } from '@parallel-fluidics/constants';
import {
  collection,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { useMemo } from 'react';
import {
  useFirestoreCollectionData,
} from 'reactfire';

const useActiveProjects = (limit) => {
  const { data: activeOrders } = useFirestoreCollectionData(query(
    collection(getFirestore(), 'orders'),
    where('status', '==', 'In progress'),
  ));
  return useMemo(() => {
    const projects = [];
    (activeOrders || []).forEach((order) => {
      (order?.shipments || []).forEach((shipment) => {
        (shipment?.lineItems || []).forEach((lineItem) => {
          projects.push({
            lineItemId: lineItem.id,
            customerName: order.customer,
            projectNumber: lineItem.projectId,
            shipDate: shipment.targetShipDate,
            steps: lineItem.steps,
            notes: lineItem.notes,
          });
        });
      });
    });
    projects.sort((a, b) => new Date(a.shipDate).getTime() - new Date(b.shipDate).getTime());
    return projects.slice(0, limit);
  }, [activeOrders, limit]);
};

const useShippedProjects = () => {
  const { data: activeOrders } = useFirestoreCollectionData(query(
    collection(getFirestore(), 'orders'),
    where('status', '==', 'Shipped'),
  ));
  return useMemo(() => {
    const projects = [];
    (activeOrders || []).forEach((order) => {
      (order?.shipments || []).forEach((shipment) => {
        (shipment?.lineItems || []).forEach((lineItem) => {
          if (lineItem.steps[0]?.goal > 0) {
            projects.push({
              lineItemId: lineItem.id,
              customerName: order.customer,
              projectNumber: lineItem.projectId,
              shipDate: shipment.targetShipDate,
              steps: lineItem.steps,
            });
          }
        });
      });
    });
    projects.sort((a, b) => new Date(b.shipDate).getTime() - new Date(a.shipDate).getTime());
    return projects;
  }, [activeOrders]);
};

const useProjectStatus = (lineItemSteps, lineItemId = 'nonexistent') => {
  const memoizedQuery = useMemo(() => query(
    collection(getFirestore(), 'travelers'),
    where('lineItem', '==', lineItemId),
  ), [lineItemId]);

  const { status: queryStatus, data: travelers } = useFirestoreCollectionData(memoizedQuery, { idField: 'id' });
  const projectStatus = useMemo(() => {
    if (queryStatus === 'success') {
      const retParts = lineItemSteps?.map((step) => ({
        // use index instead of name, because name is not unique
        inspected: 0,
        toBeInspected: 0,
        rejectCosmetic: 0,
        rejectMajor: 0,
        tuning: 0,
        unknown: 0,
        type: step.type,
      })) || {};
      if (!travelers.length) return retParts;

      travelers.forEach((traveler) => {
        let isRejected = false;
        traveler.steps?.forEach((step, index) => {
          if (!retParts[index]) { // use index instead of name, because name is not unique
            retParts[index] = {
              inspected: 0,
              toBeInspected: 0,
              rejectCosmetic: 0,
              rejectMajor: 0,
              tuning: 0,
              unknown: 0,
              type: step.type,
            };
          }

          // no need to calculate if the part is rejected
          if (isRejected) return;
          if (
            (step.type === StepTypes.MOLDING || step.type === StepTypes.BONDING)
            && !step.runId?.length
          ) {
            isRejected = true;
            return;
          }
          if (step.inspected === InspectionValues.NOT_INSPECTED) {
            retParts[index].toBeInspected += 1;
            isRejected = true;
          } else if (step.inspected === InspectionValues.PASSED) {
            retParts[index].inspected += 1;
          } else if (step.inspected === InspectionValues.REJECT_MAJOR) {
            retParts[index].rejectMajor += 1;
            isRejected = true;
          } else if (step.inspected === InspectionValues.REJECT_COSMETIC) {
            retParts[index].rejectCosmetic += 1;
            isRejected = true;
          } else if (step.inspected === InspectionValues.TUNING) {
            retParts[index].tuning += 1;
            isRejected = true;
          } else {
            retParts[index].unknown += 1;
            isRejected = true;
          }
        });
      });

      // calculate toBeInspected
      Object.keys(retParts).forEach((key) => {
        const keyNum = parseInt(key, 10);
        const previousAvailable = keyNum === 0 ? travelers.length : retParts[keyNum - 1].inspected;
        const part = retParts[key];
        // for bonding and molding, we need to consider runId exists or not
        if (part.type !== StepTypes.MOLDING && part.type !== StepTypes.BONDING) {
          part.toBeInspected = previousAvailable
          - part.inspected
          - part.rejectCosmetic
          - part.rejectMajor
          - part.tuning
          - part.unknown;
        }
      });
      return retParts;
    }
    return undefined;
  }, [queryStatus, lineItemSteps, travelers]);
  return { projectStatus, queryStatus };
};

const useShipDate = (shipDate) => useMemo(() => {
  const daysUnitlShip = Math.ceil((new Date(`${shipDate} 00:00:00`) - new Date()) / (1000 * 60 * 60 * 24));
  if (daysUnitlShip === 1) {
    return 'Ships Tomorrow';
  }
  if (daysUnitlShip === 0) {
    return 'Ships Today';
  }
  return `Ships in ${daysUnitlShip} Days`;
}, [shipDate]);

export {
  useActiveProjects,
  useShippedProjects,
  useShipDate,
  useProjectStatus,
};
