import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useQuery } from '@tanstack/react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { usePart } from '../../features/firebase';
import UserEmail from '../UserEmail';

function TransferPart({ partId }) {
  const { part } = usePart(partId);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [finishedSuccessfully, setFinishedSuccessfully] = useState(false);

  return (
    <>
      <Formik
        initialValues={{ userEmail: '' }}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          const [userEmail, setUserEmail] = useState(null);
          const { data: user, isPending: isUserLoading, error: userError } = useQuery({
            queryKey: ['getUserIDByEmail', { userEmail }],
            queryFn: async () => {
              if (!userEmail) {
                return undefined;
              }
              const functions = getFunctions();
              const getUserIDByEmail = httpsCallable(functions, 'getUserIDByEmail');
              const result = await getUserIDByEmail({ userEmail });
              return { email: userEmail, id: result.data };
            },
          });

          const handleTransfer = () => {
            if (!part || !part.uid) {
              return;
            }
            if (!user?.id) {
              setError('Missing new user ID');
              return;
            }
            setLoading(true);
            setError(null);
            const functions = getFunctions();
            const transferPart = httpsCallable(functions, 'transferPart');
            transferPart({ partID: partId, oldUserID: part.uid, newUserID: user?.id })
              .then(() => { setFinishedSuccessfully(true); })
              .catch((err) => { setError(err.message); })
              .finally(() => { setLoading(false); });
          };

          const handleClear = () => {
            setFieldValue('userEmail', '');
            setUserEmail(null);
            setError(null);
            setFinishedSuccessfully(false);
          };

          // Disable transfer for parts created before 2023-04-26
          // See https://github.com/parallel-fluidics/parallel-fluidics/issues/27
          // for more information
          if (part.uploadedAt && part.uploadedAt < 1682467200000) {
            return (
              <Modal
                visible={isModalOpen}
                onDismiss={() => { setIsModalOpen(false); }}
                header="Unable to transfer part"
              >
                <p>
                  This quote uses an outdated data model and cannot be transferred safely.
                  Please contact Andy if you need to transfer this part.
                </p>
              </Modal>
            );
          }

          if (finishedSuccessfully) {
            return (
              <Modal
                visible={isModalOpen}
                onDismiss={() => { setIsModalOpen(false); handleClear(); }}
                header="Transfer part"
              >
                <p>Successfully transferred part!</p>
              </Modal>
            );
          }

          if (error) {
            return (
              <Modal
                visible={isModalOpen}
                onDismiss={() => { setIsModalOpen(false); handleClear(); }}
                header="Transfer part"
              >
                <p>
                  Error:
                  {' '}
                  <span style={{ fontWeight: 800 }}>{error}</span>
                </p>
              </Modal>
            );
          }

          return (
            <Modal
              visible={isModalOpen}
              onDismiss={() => { setIsModalOpen(false); handleClear(); }}
              header="Transfer part"
              footer={(
                <Box float="right">
                  <SpaceBetween size="xs" direction="horizontal">
                    <Button onClick={() => { setIsModalOpen(false); handleClear(); }}>
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      disabled={!user?.id}
                      loading={loading}
                      onClick={handleTransfer}
                    >
                      Transfer
                    </Button>
                  </SpaceBetween>
                </Box>
                )}
            >
              { user?.id ? (
                <SpaceBetween direction="vertical" size="xs">
                  <Box>
                    This part is currently owned by
                    {' '}
                    <span style={{ fontWeight: 800 }}>{part?.uid ? <UserEmail userID={part.uid} /> : '(Loading...)'}</span>
                    {' '}
                    and will be transferred to
                    {' '}
                    <span style={{ fontWeight: 800 }}><UserEmail userID={user?.id} /></span>
                    . Click the button below to complete the transfer.
                  </Box>
                  <Button
                    kind="danger"
                    onClick={handleClear}
                    disabled={!user?.id}
                  >
                    Choose a different user
                  </Button>
                </SpaceBetween>
              ) : (
                <SpaceBetween direction="vertical" size="xs">
                  <Box>
                    This part is currently owned by
                    {' '}
                    <span style={{ fontWeight: 800 }}>{part && part.uid ? <UserEmail userID={part.uid} /> : '(Loading...)'}</span>
                    . Who would you like to transfer it to?
                  </Box>
                  <FormField
                    description="Search for a user by email address"
                    errorText={userError?.message}
                  >
                    <Input
                      onChange={({ detail }) => setFieldValue('userEmail', detail.value)}
                      value={values.userEmail}
                      disabled={isUserLoading}
                    />
                  </FormField>
                  <Button
                    onClick={() => {
                      const newEmail = values.userEmail.trim();
                      setUserEmail(newEmail);
                    }}
                    loading={isUserLoading}
                  >
                    Search
                  </Button>
                </SpaceBetween>
              )}
            </Modal>
          );
        } }
      </Formik>
      <Button
        onClick={() => { setIsModalOpen(true); }}
      >
        Transfer part
      </Button>
    </>
  );
}

TransferPart.propTypes = {
  partId: PropTypes.string.isRequired,
};

export default TransferPart;
