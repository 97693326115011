/* eslint-disable max-len */
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import {
  colorBorderDividerDefault,
  colorBorderDividerSecondary,
} from '@cloudscape-design/design-tokens';
import { StepTypes } from '@parallel-fluidics/constants';
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
} from 'firebase/firestore';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const stepOptions = Object.values(StepTypes).map((value) => ({ label: value, value }));

export default function LineItemContent({ shipmentIndex, lineItemIndex: idx }) {
  const {
    values: { shipments = [] }, setFieldValue, errors,
  } = useFormikContext();
  const [disableEdit, setDisableEdit] = useState(true);

  const currentLineItems = shipments[shipmentIndex]?.lineItems || [];
  const item = currentLineItems[idx] || {};

  useEffect(() => {
    if (item.id) {
      const checkIfTravelerExists = async () => {
        const queryRef = query(
          collection(getFirestore(), 'travelers'),
          where('lineItem', '==', item.id),
          limit(1),
        );
        const querySnapshot = await getDocs(queryRef);

        if (!querySnapshot.empty) {
          setDisableEdit(true);
        } else {
          setDisableEdit(false);
        }
      };
      checkIfTravelerExists();
    } else {
      setDisableEdit(false);
    }
  }, [item?.id]);

  return (
    <Container
      header={(
        <Header
          actions={idx !== 0 && (
          <Button
            type="button"
            disabled={disableEdit}
            onClick={() => {
              const updatedLineItems = currentLineItems.filter((_, i) => i !== idx);
              setFieldValue(`shipments[${shipmentIndex}].lineItems`, updatedLineItems);
            }}
          >
            Delete LineItem
          </Button>
          )}
        >
          {`Line Item ${idx + 1}`}
        </Header>
        )}
    >
      <ColumnLayout columns={4}>
        <FormField
          label="Project ID"
          errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.projectId}
        >
          <Input
            name={`shipments[${shipmentIndex}].lineItems[${idx}].projectId`}
            value={shipments[shipmentIndex]?.lineItems[idx]?.projectId}
            onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].projectId`, detail.value); }}
          />
        </FormField>
        <FormField
          label="Quantity"
          errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.quantity}
        >
          <Input
            name={`shipments[${shipmentIndex}].lineItems[${idx}].quantity`}
            value={shipments[shipmentIndex]?.lineItems[idx]?.quantity}
            type="number"
            onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].quantity`, detail.value); }}
          />
        </FormField>
        <FormField
          label="Bonding"
          errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.bonding}
        >
          <Input
            name={`shipments[${shipmentIndex}].lineItems[${idx}].bonding`}
            value={shipments[shipmentIndex]?.lineItems[idx]?.bonding}
            onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].bonding`, detail.value); }}
          />
        </FormField>
        <FormField
          label="Notes"
          errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.notes}
        >
          <Textarea
            name={`shipments[${shipmentIndex}].lineItems[${idx}].notes`}
            value={shipments[shipmentIndex]?.lineItems[idx]?.notes}
            onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].notes`, detail.value); }}
          />
        </FormField>
      </ColumnLayout>
      <div className="table_container">
        <Box variant="h5">
          Steps
        </Box>
        <table className="line_item_table">
          <thead style={{ borderColor: colorBorderDividerDefault }}>
            <tr>
              <th>Type</th>
              <th>Goal</th>
              <th>Name</th>
              <th>Note</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody style={{ borderColor: colorBorderDividerSecondary }}>
            {item.steps?.map((step, stepIdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={`${idx}_step_${stepIdx}`}>
                <td>
                  <FormField
                    errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.steps?.[stepIdx]?.type}
                  >
                    <Select
                      disabled={disableEdit}
                      expandToViewport
                      selectedOption={{ label: step.type, value: step.type }}
                      onChange={({ detail }) => {
                        setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].steps[${stepIdx}].type`, detail.selectedOption.value);
                        setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].steps[${stepIdx}].name`, detail.selectedOption.value);
                      }}
                      options={stepOptions}
                    />
                  </FormField>
                </td>
                <td>
                  <FormField
                    errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.steps?.[stepIdx]?.goal}
                  >
                    <Input
                      value={step.goal}
                      type="number"
                      onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].steps[${stepIdx}].goal`, parseInt(detail.value, 10)); }}
                    />
                  </FormField>
                </td>
                <td>
                  <FormField
                    errorText={errors?.shipments?.[shipmentIndex]?.lineItems?.[idx]?.steps?.[stepIdx]?.name}
                  >
                    <Input
                      value={step.name}
                      onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].steps[${stepIdx}].name`, detail.value); }}
                    />
                  </FormField>
                </td>
                <td>
                  <Textarea
                    value={step.note}
                    onChange={({ detail }) => { setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].steps[${stepIdx}].note`, detail.value); }}
                  />
                </td>
                <td style={{ textAlign: 'center' }}>
                  <Button
                    formAction="none"
                    iconName="close"
                    variant="icon"
                    disabled={disableEdit || item.steps?.length === 1}
                    onClick={() => {
                      const updatedSteps = item.steps?.filter((_, i) => i !== stepIdx);
                      setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].steps`, updatedSteps);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Box padding={{ top: 'm' }}>
          <SpaceBetween direction="horizontal" size="m" alignItems="center" padding>
            <Button
              formAction="none"
              disabled={disableEdit}
              onClick={() => {
                setFieldValue(`shipments[${shipmentIndex}].lineItems[${idx}].steps`, [
                  ...(item.steps || []),
                  {
                    type: StepTypes.MOLDING, goal: null, name: StepTypes.MOLDING, note: '',
                  },
                ]);
              }}
            >
              Add new step
            </Button>
            {disableEdit && (
            <Box color="text-status-inactive">
              You cannot edit the steps of this line item as it has travelers associated with it.
            </Box>
            )}
          </SpaceBetween>
        </Box>
      </div>
    </Container>
  );
}

LineItemContent.propTypes = {
  shipmentIndex: PropTypes.number.isRequired,
  lineItemIndex: PropTypes.number.isRequired,
};
