import {
  Button,
  Checkbox,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  FormField,
  Header,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
  Spinner,
  Textarea,
} from '@cloudscape-design/components';
import {
  FailureTags, InspectionValues, StepProcessMap, StepTypes,
} from '@parallel-fluidics/constants';
import {
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  useFirestoreDocData,
  useUser,
} from 'reactfire';

import Changelog from './changelog';
import ProcessMultiselect from './processMultiselect';
import UnsavedAlert from './unsavedAlert';

function TravelerPage() {
  const [error, setError] = useState(null);

  const { data: user } = useUser();
  const { travelerId } = useParams();
  const { data: traveler, status: travelerStatus, error: travelerError } = useFirestoreDocData(doc(getFirestore(), 'travelers', travelerId));

  const inspectionOptions = useMemo(() => Object.values(InspectionValues).map((value) => ({
    value,
    label: value,
  })), []);

  const failureTagsOptions = useMemo(() => Object.values(FailureTags).map((value) => ({
    value,
    label: value,
  })), []);

  const initialValues = useMemo(() => {
    if (!traveler || travelerStatus !== 'success') {
      return {};
    }
    return { ...traveler };
  }, [traveler, travelerStatus]);

  if (travelerStatus === 'loading') {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            Edit Traveler
          </Header>
                )}
      >
        <Container>
          {' '}
          <Spinner />
        </Container>
      </ContentLayout>
    );
  }

  if (travelerStatus === 'error') {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            Edit Traveler
          </Header>
                 )}
      >
        <Container>
          <Flashbar
            items={[{
              header: 'Error loading traveler',
              content: travelerError,
              type: 'error',
            }]}
          />
        </Container>
      </ContentLayout>
    );
  }

  if (!traveler) {
    return (
      <ContentLayout
        header={(
          <Header variant="h2">
            {`Edit Traveler ${travelerId}`}
          </Header>
                 )}
      >
        <Container>
          <Flashbar
            items={[{
              header: 'Traveler not found',
              content: `No traveler found with ID ${travelerId}. If you have a traveler sticker with this id, it might mean the traveler hasn't been used yet. Try starting a forming run with this travler!`,
              type: 'warning',
            }]}
          />
        </Container>
      </ContentLayout>
    );
  }

  return (
    <SpaceBetween direction="vertical" size="l">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setError(null);
          try {
            await updateDoc(doc(getFirestore(), 'travelers', travelerId), {
              project: values.project || '',
              tool: values.tool || '',
              steps: values.steps,
              finalInspection: values.finalInspection || 'Not inspected',
              finalFailureTags: values.finalFailureTags || [],
              shipped: values.shipped || false,
              notes: values.notes || '',
              _updatedBy: {
                email: user.email,
                timestamp: serverTimestamp(),
                uid: user.uid,
              },
            });
          } catch (e) {
            setError(e.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit, setFieldValue, values, isSubmitting, dirty, errors,
        }) => (
          <ContentLayout
            header={(
              <Header
                variant="h2"
                actions={(
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={!dirty}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              >
                Edit Traveler
              </Header>
            )}
          >
            <UnsavedAlert dirty={dirty} />
            <SpaceBetween direction="vertical" size="l">
              {error ? (
                <Flashbar
                  items={[{
                    header: 'Error saving traveler',
                    content: error,
                    type: 'error',
                  }]}
                />
              ) : null}

              {/* Project */}
              <Container>
                <Header variant="h3">
                  {`Traveler ID: ${travelerId}`}
                </Header>
                <ColumnLayout columns={2}>
                  <FormField
                    label="Project"
                    errorText={errors.project}
                    stretch
                  >
                    <Input
                      value={values.project}
                      onChange={({ detail }) => setFieldValue('project', detail.value)}
                    />
                  </FormField>
                  <FormField
                    label="Tool"
                    errorText={errors.tool}
                    stretch
                  >
                    <Input
                      value={values.tool}
                      onChange={({ detail }) => setFieldValue('tool', detail.value)}
                    />
                  </FormField>
                </ColumnLayout>
              </Container>

              {values.steps?.map((step, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Container key={index}>
                  <Header variant="h3">
                    {step.name}
                  </Header>
                  <SpaceBetween size="xs">
                    <div className="even-columns">
                      {(step.type === StepTypes.MOLDING || step.type === StepTypes.BONDING) && (
                      <FormField
                        stretch
                        label="Run ID"
                        errorText={errors.steps && errors.steps[index]?.runId}
                      >
                        <ProcessMultiselect
                          projectId={values.project}
                          processName={StepProcessMap[step.type]}
                          selectedOptions={step.runId}
                          setValue={(runId) => setFieldValue(`steps[${index}].runId`, runId)}
                        />
                      </FormField>
                      )}
                      <FormField
                        stretch
                        label="Inspection"
                        errorText={errors.steps && errors.steps[index]?.inspected}
                      >
                        <Select
                          selectedOption={{ value: step.inspected, label: step.inspected }}
                          onChange={({ detail }) => {
                            const { value } = detail.selectedOption;
                            if (
                              value !== InspectionValues.REJECT_MAJOR
                              && value !== InspectionValues.REJECT_COSMETIC
                            ) {
                              setFieldValue(`steps[${index}].failureTags`, []);
                            }
                            setFieldValue(`steps[${index}].inspected`, value);
                          }}
                          options={inspectionOptions}
                        />
                      </FormField>
                      {(
                        step.inspected === InspectionValues.REJECT_MAJOR
                        || step.inspected === InspectionValues.REJECT_COSMETIC
                        || step.failureTags?.length > 0
                      ) && (
                      <FormField
                        stretch
                        label="Failure tags"
                      >
                        <Multiselect
                          selectedOptions={
                            step.failureTags?.map((tag) => ({ value: tag, label: tag })) || []
                          }
                          onChange={({ detail }) => setFieldValue(`steps[${index}].failureTags`, detail.selectedOptions?.map((option) => option.value) || [])}
                          options={failureTagsOptions}
                        />
                      </FormField>
                      )}
                    </div>
                    <FormField
                      label="Inspection Note"
                      errorText={errors.steps && errors.steps[index]?.notes}
                      stretch
                    >
                      <Textarea
                        value={step.inspectedNote}
                        onChange={({ detail }) => setFieldValue(`steps[${index}].inspectedNote`, detail.value)}
                      />
                    </FormField>
                  </SpaceBetween>
                </Container>
              ))}

              {/* Final Inspection */}
              <Container>
                <Header variant="h3">
                  Final Inspection
                </Header>
                <SpaceBetween size="xs">
                  <div className="even-columns">
                    <FormField
                      label="Inspection"
                      errorText={errors.finalInspection}
                      stretch
                    >
                      <Select
                        selectedOption={{
                          value: values.finalInspection, label: values.finalInspection,
                        }}
                        onChange={({ detail }) => {
                          const { value } = detail.selectedOption;
                          if (
                            value !== InspectionValues.REJECT_MAJOR
                              && value !== InspectionValues.REJECT_COSMETIC
                          ) {
                            setFieldValue('finalFailureTags', []);
                          }
                          setFieldValue('finalInspection', value);
                        }}
                        options={inspectionOptions}
                      />
                    </FormField>
                    {(
                      values.finalInspection === InspectionValues.REJECT_MAJOR
                        || values.finalInspection === InspectionValues.REJECT_COSMETIC
                        || values.finalFailureTags?.length > 0
                    ) && (
                    <FormField
                      stretch
                      label="Failure tags"
                      errorText={errors.finalFailureTags}
                    >
                      <Multiselect
                        selectedOptions={
                        values.finalFailureTags?.map((tag) => ({ value: tag, label: tag })) || []
                      }
                        onChange={({ detail }) => setFieldValue('finalFailureTags', detail.selectedOptions?.map((option) => option.value) || [])}
                        options={failureTagsOptions}
                      />
                    </FormField>
                    )}
                  </div>
                  <FormField
                    label="Additional notes"
                    errorText={errors.notes}
                    stretch
                  >
                    <Textarea
                      value={values.notes}
                      onChange={({ detail }) => setFieldValue('notes', detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>

              {/* Shipped */}
              <Container>
                <Header variant="h3">
                  Shipped
                </Header>
                <FormField
                  errorText={errors.shipped}
                  stretch
                >
                  <Checkbox
                    onChange={({ detail }) => {
                      setFieldValue('shipped', detail.checked);
                    }}
                    checked={values.shipped || false}
                  >
                    Shipped
                  </Checkbox>
                </FormField>
              </Container>
              {/* Save Button */}
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={!dirty}
                loading={isSubmitting}
              >
                Save
              </Button>
            </SpaceBetween>
          </ContentLayout>
        )}
      </Formik>
      <Changelog travelerId={travelerId} />
    </SpaceBetween>
  );
}

export default TravelerPage;
