import {
  Alert,
  Box,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
  TextContent,
} from '@cloudscape-design/components';
import PropTypes from 'prop-types';
import React from 'react';

import {
  useProjectStatus,
  useShipDate,
} from './hooks';

// StatusDisplay only been used by ActiveOrderStatus
function StatusDisplay({ goal, statusData, title }) {
  return (
    <SpaceBetween size="m">
      <div>
        <Box variant="awsui-key-label">{title}</Box>
        <Box variant="awsui-value-large">
          {`${statusData.inspected}/${goal} Inspected`}
        </Box>
      </div>
      <div>
        <Box variant="awsui-key-label">To-be inspected</Box>
        <div>{statusData.toBeInspected}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Reject (Cosmetic)</Box>
        <div>{statusData.rejectCosmetic}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Reject (Major)</Box>
        <div>{statusData.rejectMajor}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Tuning</Box>
        <div>{statusData.tuning}</div>
      </div>
      {statusData.unknown > 0 && (
        <div>
          <Box variant="awsui-key-label">Unknown</Box>
          <div>{statusData.unknown}</div>
        </div>
      )}
    </SpaceBetween>
  );
}
StatusDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  goal: PropTypes.number.isRequired,
  statusData: PropTypes.shape({
    inspected: PropTypes.number.isRequired,
    toBeInspected: PropTypes.number.isRequired,
    rejectCosmetic: PropTypes.number.isRequired,
    rejectMajor: PropTypes.number.isRequired,
    tuning: PropTypes.number.isRequired,
    unknown: PropTypes.number,
  }).isRequired,
};

function ActiveOrderStatus({
  customerName,
  lineItemId,
  projectNumber,
  shipDate,
  steps,
  notes = null,
}) {
  const { projectStatus, queryStatus } = useProjectStatus(steps, lineItemId);
  const shipDateText = useShipDate(shipDate);

  if (queryStatus === 'loading') {
    return (
      <Container header={<Header>{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Header>}>
        <StatusIndicator type="loading">Loading...</StatusIndicator>
      </Container>
    );
  }
  if (queryStatus === 'error') {
    return (
      <Container header={<Header>{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Header>}>
        <StatusIndicator type="error">Error</StatusIndicator>
      </Container>
    );
  }
  return (
    <Container header={<Header>{`[${projectNumber}] ${customerName} - ${shipDateText}`}</Header>}>
      <SpaceBetween direction="vertical" size="m">
        {notes ? (
          <Alert statusIconAriaLabel="Warning" type="warning">
            <TextContent>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: notes }} />
            </TextContent>
          </Alert>
        ) : null}
        <ColumnLayout
          columns={steps?.filter((step) => step.goal > 0).length}
          variant="text-grid"
          minColumnWidth={170}
        >
          {steps?.map((step, index) => (
            step.goal > 0 && (
              <StatusDisplay
              // eslint-disable-next-line react/no-array-index-key
                key={`${step.name}_${index}`}
                title={step.name}
                goal={step.goal}
                statusData={projectStatus[index]}
              />
            )
          ))}
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );
}

ActiveOrderStatus.propTypes = {
  customerName: PropTypes.string.isRequired,
  lineItemId: PropTypes.string.isRequired,
  projectNumber: PropTypes.string.isRequired,
  shipDate: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    goal: PropTypes.number.isRequired,
  })).isRequired,
  notes: PropTypes.string,
};

export default ActiveOrderStatus;
