import {
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  Flashbar,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  Popover,
  Select,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import { ProcessStepMap } from '@parallel-fluidics/constants';
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useUser } from 'reactfire';
import * as Yup from 'yup';

import { PressOrientation } from '../../../constants/pressOrientation';
import {
  usePLCValue,
  usePLCValues,
  usePLCWrite,
} from '../../../features/plc/context';
import TravelerInput from '../../QRCodeScanner/travelerInput';
import { usePressHandle, useProgramHandle } from '../hooks/context';
import useCurrentConfig from '../hooks/useCurrentConfig';
import MaterialSelector from './materialSelector';
import parseTime from './parseTime';
import PistonSelector from './pistonSelector';
import ProjectSelector from './projectSelector';

function StartAutomationModal({
  isOpen,
  close,
  process,
}) {
  const { data: user } = useUser();

  const pressHandle = usePressHandle();
  const press = useMemo(() => pressHandle.toLowerCase(), [pressHandle]);
  const programHandle = useProgramHandle();
  const automationEnabledHandle = useMemo(() => `${programHandle}.bProcessAutomationEnabled`, [programHandle]);
  const sessionHandle = useMemo(() => `${programHandle}.sFirebaseSessionID`, [programHandle]);

  const { config, configError } = useCurrentConfig(process);
  const plcHandles = useMemo(() => {
    if (!config.handles) {
      return [];
    }
    return config.handles?.reduce((acc, { handle }) => ([
      ...acc, `${programHandle}.${handle}`]), []);
  }, [config, programHandle]);
  const plcValues = usePLCValues(plcHandles);
  const pressOrientation = usePLCValue(`MAIN.${pressHandle}_Bonding_Orientation`);
  const write = usePLCWrite();

  const initialValues = useMemo(() => ({
    process,
    press,
    project: null,
    selectedStep: null,
    otherProject: '',
    hypothesis: '',
    number: 0,
    production: false,
    ...plcHandles.reduce((acc, handle) => (
      { ...acc, [handle]: plcValues[handle] }), {}),

    travelers: [],
    skipTravelers: false,

    // forming line specific
    material: null,
    otherMaterial: '',
    mass: 0,
    piston: '',
    tool: '',

    // bonding line specific
    numberOfChips: 1,
    pressOrientation: pressOrientation !== undefined ? PressOrientation.toString(pressOrientation) : '',
  }), [process, press, plcHandles, pressOrientation, plcValues]);

  const validationSchema = Yup.object().shape({
    project: Yup.object().shape({
      value: Yup.string().required('Project is required'),
    }).nullable().required('Project is required'),
    selectedStep: Yup.object().nullable().when('project.steps', {
      is: (steps) => Array.isArray(steps) && steps.length > 0,
      then: Yup.object().required('Step is required'),
    }),
    otherProject: Yup.string().when('project.value', {
      is: 'other',
      then: Yup.string().required('Project is required'),
    }),
    hypothesis: Yup.string().when('production', {
      is: false,
      then: Yup.string().required('Hypothesis is required'),
    }),
    number: Yup.number().typeError('Chip number is required').min(1, 'Chip number must be greater than zero'),
    travelers: Yup.array(Yup.string()).when('skipTravelers', {
      is: false,
      then: Yup.array(Yup.string()).min(1, 'Add at least one traveler or toggle the skip travelers button'),
    }),

    // forming line specific
    material: Yup.object().nullable().when('process', {
      is: 'forming',
      then: (schema) => schema.required('Material is required'),
    }),
    otherMaterial: Yup.string().when('material.value', {
      is: 'other',
      then: Yup.string().required('Material is required'),
    }),
    mass: Yup.number().typeError('Mass is required').when('process', {
      is: 'forming',
      then: (schema) => schema.moreThan(0, 'Mass must be greater than zero'),
    }),
    piston: Yup.string().when('process', {
      is: 'forming',
      then: Yup.string().required('Piston is required'),
    }),
    tool: Yup.string().when('process', {
      is: 'forming',
      then: Yup.string().required('Tool name is required'),
    }),

    // bonding line specific
    numberOfChips: Yup.number().when('process', {
      is: 'bonding',
      then: Yup.number().moreThan(0, 'Number of chips must be greater than zero').required('Number of chips is required'),
    }),
  });

  const [stepOptions, setStepOptions] = useState([]);

  const updateChipNumber = useCallback((project, setFieldValue) => {
    if (project && project !== 'other') {
      getDocs(query(
        collection(getFirestore(), 'process_logs'),
        where('project', '==', project),
        where('process', '==', process),
        orderBy('number', 'desc'),
        limit(1),
      )).then((querySnapshot) => {
        if (querySnapshot.empty) {
          setFieldValue('number', 1);
          return;
        }
        querySnapshot.forEach((snapshot) => {
          setFieldValue('number', snapshot.data().number + 1);
        });
      });
    }
  }, [process]);

  const updateSelectedSteps = useCallback((lineItemSteps, setFieldValue) => {
    if (!lineItemSteps?.length) return;
    const options = [];
    let enabledCount = 0;
    let defaultSelected = null;
    lineItemSteps?.forEach((step, index) => {
      const enabled = step.type === ProcessStepMap[process];
      const option = {
        label: step.name,
        value: index,
        disabled: !enabled,
        description: step.note,
      };
      // set the first available step as the default selected
      if (enabled && !defaultSelected) {
        defaultSelected = option;
      }
      if (enabled) enabledCount += 1;
      options.push(option);
    });
    setStepOptions(options);
    // we only set default selected if there is only one enabled step,
    // otherwise we let the user choose
    if (defaultSelected && enabledCount === 1) {
      setFieldValue('selectedStep', defaultSelected);
    } else {
      setFieldValue('selectedStep', null);
    }
  }, [process]);

  if (typeof document === 'undefined') {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            let project = values.project?.value || '';
            if (values.project?.value === 'other') {
              project = values.otherProject;
            }
            const logData = {
              process,
              press,
              user: user?.uid,
              start: Timestamp.fromDate(new Date(Date.now())),
              hypothesis: values.hypothesis,
              project,
              production: values.production,
              number: values.number,
              travelers: values.travelers || [],
              plcConfig: {
                version: config.version,
                ...plcHandles.reduce((acc, handle) => {
                  if (values[handle]) {
                    return { ...acc, [handle.replace(`${programHandle}.`, '')]: values[handle] };
                  }
                  return acc;
                }, {}),
              },
            };
            if (values.project?.orderId) {
              logData.order = values.project.orderId;
            }
            if (values.project?.lineItemId) {
              logData.lineItem = values.project.lineItemId;
            }
            if (values.selectedStep?.value) {
              logData.currentStepIndex = values.selectedStep.value;
            }
            if (values.process === 'forming') {
              let material = values.material?.value || '';
              if (values.material === 'other') {
                material = values.otherMaterial;
              }
              logData.material = material;
              logData.mass = values.mass;
              logData.piston = values.piston;
              logData.tool = values.tool;
            }
            if (values.process === 'bonding') {
              logData.numberOfChips = values.numberOfChips;
              logData.pressOrientation = values.pressOrientation;
            }

            const docRef = await addDoc(collection(getFirestore(), 'process_logs'), logData);

            setFieldValue('hypothesis', '');
            setFieldValue('number', 0);
            setFieldValue('project', null);
            setFieldValue('mass', 0);
            setFieldValue('travelers', []);
            setSubmitting(false);
            write({
              handle: sessionHandle, value: docRef.id,
            });
            write({ handle: automationEnabledHandle, value: true });
            close();
          }}
        >
          {({
            values, handleSubmit, setFieldValue, errors,
          }) => (
            <Modal
              size="max"
              visible={isOpen}
              onDismiss={close}
              header="Start"
              footer={(
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={close}>Cancel</Button>
                    <Popover
                      position="top"
                      size="large"
                      triggerType="custom"
                      header="Are you sure you want to skip? No data will be saved."
                      content={(
                        <SpaceBetween size="xs">
                          <img
                            alt="Are you sure?"
                            src="https://media.giphy.com/media/qUIm5wu6LAAog/giphy.gif"
                            width="100%"
                          />
                          <Button
                            variant="primary"
                            onClick={() => {
                              write({
                                handle: sessionHandle, value: ' '.repeat(20),
                              });
                              write({ handle: automationEnabledHandle, value: true });
                              close();
                            }}
                          >
                            Confirm
                          </Button>
                        </SpaceBetween>
                       )}
                    >
                      <Button variant="link">Skip</Button>
                    </Popover>
                    <Button variant="primary" onClick={handleSubmit}>Start</Button>
                  </SpaceBetween>
                </Box>
                )}
            >
              <SpaceBetween size="m">
                {configError ? (
                  <Flashbar
                    items={[
                      {
                        header: (
                          'There was an error figuring out what config info to save. '
                                + 'Refreshing the page may fix this error. '
                                + 'You can still start the run, but config info will not save.'
                        ),
                        type: 'error',
                        content: `The original error was: ${configError}`,
                        dismissible: false,
                        id: 'config_error',
                      },
                    ]}
                  />
                ) : null}
                <Grid
                  gridDefinition={
                      values.project?.value === 'other'
                        ? [{ colspan: 6 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }]
                        : [{ colspan: 8 }, { colspan: 2 }, { colspan: 2 }]
                    }
                >
                  <ProjectSelector
                    setProject={(newProject) => {
                      setFieldValue('project', newProject);
                      updateChipNumber(newProject?.value, setFieldValue);
                      updateSelectedSteps(newProject?.steps, setFieldValue);
                    }}
                    value={values.project}
                    error={errors.project}
                    includeOther
                  />
                  {values.project?.value === 'other' ? (
                    <FormField
                      label="Custom project number"
                      description={<br />}
                      errorText={errors.otherProject}
                    >
                      <Input
                        onChange={({ detail }) => {
                          setFieldValue('otherProject', detail.value);
                        }}
                        value={values.otherProject}
                        placeholder="Custom project number"
                        invalid={!!errors.otherProject}
                        invalidText={errors.otherProject}
                      />
                    </FormField>
                  ) : null}
                  <FormField
                    label="Chip number"
                    description={<br />}
                    errorText={errors.number}
                  >
                    <Input
                      onChange={({ detail }) => {
                        const newValue = parseInt(detail.value, 10);
                        setFieldValue('number', newValue);
                      }}
                      value={values.number}
                      inputMode="numeric"
                      type="number"
                    />
                  </FormField>
                  <FormField
                    label="Production run"
                    description={<br />}
                    errorText={errors.production}
                  >
                    <Checkbox
                      onChange={({ detail }) => {
                        setFieldValue('production', detail.checked);
                      }}
                      checked={values.production}
                    >
                      Mark as production run
                    </Checkbox>
                  </FormField>
                </Grid>
                <Grid
                  gridDefinition={
                  values.project?.steps?.length > 0
                    ? [{ colspan: 6 }, { colspan: 4 }, { colspan: 2 }]
                    : [{ colspan: 8 }, { colspan: 4 }]
                }
                >
                  <TravelerInput
                    id="start-modal-travelers-input"
                    travelers={values.travelers}
                    setTravelers={(newTravelers) => {
                      setFieldValue('travelers', newTravelers);
                      setFieldValue('numberOfChips', newTravelers.length);
                    }}
                    errorText={errors.travelers}
                  />
                  {values.project?.steps?.length && (
                    <FormField
                      label="Select Step"
                      errorText={errors.selectedStep}
                    >
                      <Select
                        placeholder="Choose current step"
                        options={stepOptions}
                        onChange={({ detail }) => setFieldValue('selectedStep', detail.selectedOption)}
                        selectedOption={values.selectedStep}
                        triggerVariant="option"
                      />
                    </FormField>
                  )}
                  <FormField
                    label="Skip"
                    errorText={errors.skipTravelers}
                  >
                    <Checkbox
                      checked={values.skipTravelers}
                      onChange={({ detail }) => {
                        setFieldValue('skipTravelers', detail.checked);
                      }}
                    >
                      Skip travelers
                    </Checkbox>
                  </FormField>
                </Grid>
                { values.process === 'forming' ? (
                  <Grid
                    gridDefinition={values.material?.value === 'other' ? (
                      [{ colspan: 4 }, { colspan: 4 }, { colspan: 4 },
                        { colspan: 6 }, { colspan: 6 }]
                    ) : (
                      [{ colspan: 6 }, { colspan: 6 }, { colspan: 6 }, { colspan: 6 }]
                    )}
                  >
                    <MaterialSelector
                      setMaterial={(newMaterial) => { setFieldValue('material', newMaterial); }}
                      value={values.material}
                      error={errors.material}
                    />
                    {values.material?.value === 'other' ? (
                      <FormField
                        label="Custom material"
                        errorText={errors.otherMaterial}
                      >
                        <Input
                          value={values.otherMaterial}
                          onChange={({ detail }) => { setFieldValue('otherMaterial', detail.value); }}
                        />
                      </FormField>
                    ) : null}
                    <FormField
                      label="Material mass"
                      errorText={errors.mass}
                    >
                      <Input
                        onChange={({ detail }) => {
                          const newValue = parseFloat(detail.value);
                          setFieldValue('mass', newValue);
                        }}
                        value={values.mass}
                        inputMode="numeric"
                        type="number"
                      />
                    </FormField>
                    <PistonSelector
                      selectedValue={values.piston}
                      handleChange={(value) => { setFieldValue('piston', value); }}
                      errorText={errors.piston}
                    />
                    <FormField
                      label="Tool name"
                      errorText={errors.tool}
                    >
                      <Input
                        value={values.tool}
                        onChange={({ detail }) => { setFieldValue('tool', detail.value); }}
                      />
                    </FormField>
                  </Grid>
                ) : null}
                {values.process === 'bonding' ? (
                  <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <FormField
                      label="Number of chips"
                      errorText={errors.numberOfChips}
                    >
                      <Input
                        value={values.numberOfChips}
                        onChange={({ detail }) => { setFieldValue('numberOfChips', detail.value); }}
                        inputMode="numeric"
                        type="number"
                      />
                    </FormField>
                    <FormField
                      label="Press orientation"
                      errorText={errors.pressOrientation}
                    >
                      <Input value={values.pressOrientation} readOnly />
                    </FormField>
                  </Grid>
                ) : null}
                <FormField
                  label="Hypothesis"
                  errorText={errors.hypothesis}
                  stretch
                >
                  <Textarea
                    onChange={({ detail }) => setFieldValue('hypothesis', detail.value)}
                    value={values.hypothesis}
                    placeholder="Any changes this run? What do you expect the outcome to be?"
                  />
                </FormField>
                {config.rows.map((row) => (
                  <React.Fragment key={row.header}>
                    <Header variant="h3">
                      {row.header}
                    </Header>
                    <ColumnLayout columns={4}>
                      {row.values.map((value) => (
                        <FormField
                          label={value.label}
                          key={value.handle}
                        >
                          <Input
                            value={value.type === 'TIME'
                              ? parseTime(values[`${programHandle}.${value.handle}`])
                              : values[`${programHandle}.${value.handle}`]}
                            readOnly
                          />
                        </FormField>
                      ))}
                    </ColumnLayout>
                  </React.Fragment>
                ))}
              </SpaceBetween>
            </Modal>
          )}
        </Formik>,
        document.body,
      )}
    </>
  );
}

StartAutomationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  process: PropTypes.oneOf(['forming', 'bonding']).isRequired,
};

export default StartAutomationModal;
